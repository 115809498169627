import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isMobileNavOpen = false;
  currentOpenSubmenu = '';
  currentOpenSubSubmenu = '';

  constructor() { }

  toggleMobileNav() {
    this.isMobileNavOpen = !this.isMobileNavOpen;
  }

  toggleSubMenu(submenu: string, subSubmenu = '') {
    if (subSubmenu) {
      this.currentOpenSubmenu = submenu;
      this.currentOpenSubSubmenu = subSubmenu === this.currentOpenSubSubmenu ? '' : subSubmenu;
    } else {
      this.currentOpenSubmenu = submenu === this.currentOpenSubmenu ? '' : submenu;
    }
  }

  // toggleSubSubMenu(submenu: string, subSubmenu: string) {
  //   this.currentOpenSubmenu = submenu === this.currentOpenSubmenu ? '' : submenu;
  //   this.currentOpenSubSubmenu = subSubmenu === this.currentOpenSubSubmenu ? '' : subSubmenu;
  // }

}