/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy-policy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/novo-elements/novo-elements.ngfactory";
import * as i3 from "novo-elements";
import * as i4 from "@angular/common";
import * as i5 from "./privacy-policy.component";
import * as i6 from "@angular/common/http";
import * as i7 from "@angular/platform-browser";
var styles_PrivacyPolicyComponent = [i0.styles];
var RenderType_PrivacyPolicyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrivacyPolicyComponent, data: {} });
export { RenderType_PrivacyPolicyComponent as RenderType_PrivacyPolicyComponent };
function View_PrivacyPolicyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 0, 0, currVal_0); }); }
function View_PrivacyPolicyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "novo-loading", [], [[8, "className", 0]], null, null, i2.View_ɵq_0, i2.RenderType_ɵq)), i1.ɵdid(1, 49152, null, 0, i3.ɵq, [], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).theme || ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_PrivacyPolicyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrivacyPolicyComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrivacyPolicyComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PrivacyPolicyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-privacy-policy", [], null, null, null, View_PrivacyPolicyComponent_0, RenderType_PrivacyPolicyComponent)), i1.ɵdid(1, 114688, null, 0, i5.PrivacyPolicyComponent, [i6.HttpClient, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrivacyPolicyComponentNgFactory = i1.ɵccf("app-privacy-policy", i5.PrivacyPolicyComponent, View_PrivacyPolicyComponent_Host_0, {}, {}, []);
export { PrivacyPolicyComponentNgFactory as PrivacyPolicyComponentNgFactory };
