<header>
  <div class="header-content">
    <div class="logo-container">
      <a href="https://getcrg.com">
        <img src="../../assets/images/CRGMainDarkerBlue-uai-258x59.png" alt="logo">
      </a>
    </div>

    <div class="nav-container">

      <div class="nav-links">
        <ul>
          <li>
            <a href="https://getcrg.com/">Home</a>
          </li>
          <li>
            <a href="https://getcrg.com/crg-services/">CRG Services<i class="fa fa-angle-down fa-dropdown"></i></a>
            <div class="sub-menu">
              <div class="sub-menu-column">
                <div class="sub-menu-header" href="https://getcrg.com/executive-recruitment/"><i class="fa fa-search"></i>SEARCH</div>
                <ul>
                  <li><a href="https://getcrg.com/executive-recruitment/">SEARCH HOME</a></li>
                  <li><a href="https://getcrg.com/testimonials/">Testimonials</a></li>
                  <li><a href="https://getcrg.com/our-process/">Our Process</a></li>
                  <li><a href="https://getcrg.com/search-leadership/">Leadership</a></li>
                  <li><a href="https://getcrg.com/candidate-support/">Candidate Support</a></li>
                </ul>
              </div>
              <div class="sub-menu-column">
                <div class="sub-menu-header" href="https://getcrg.com/staffing-agency/"><i class="fa fa-users2"></i>STAFFING</div>
                <ul>
                  <li><a href="https://getcrg.com/staffing-agency/">STAFFING HOME</a></li>
                  <li><a href="https://getcrg.com/it-staffing/">Information Technology</a></li>
                  <li><a href="https://getcrg.com/accounting-recruitment/">Accounting & Finance</a></li>
                  <li><a href="https://getcrg.com/admin-hr-recruitment/">HR & Administration</a></li>
                </ul>
              </div>
              <div class="sub-menu-column">
                <div class="sub-menu-header" href="https://getcrg.com/it-solutions/"><i class="fa fa-desktop2"></i>SOLUTIONS</div>
                <ul>
                  <li><a href="https://getcrg.com/it-solutions/">IT SOLUTIONS</a></li>
                  <li><a href="https://getcrg.com/it-project-management/">Project Management</a></li>
                  <li><a href="https://getcrg.com/managed-it-services/">Managed IT Services</a></li>
                  <li><a href="https://getcrg.com/it-consulting-services/">Consulting Services</a></li>
                  <li><a href="https://getcrg.com/patch-management-service/">Patch Management as a Service</a></li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <a href="https://getcrg.com/locations/">Locations</a>
          </li>
          <li>
            <a href="">About CRG<i class="fa fa-angle-down fa-dropdown"></i></a>
            <div class="sub-menu single-column">
              <div class="sub-menu-column">
                <ul>
                  <li><a href="https://getcrg.com/leadership/">Leadership</a></li>
                  <li><a href="https://getcrg.com/crg-values/">CRG Values</a></li>
                  <li><a href="https://getcrg.com/community-focus/">Community Focus</a></li>
                  <li><a href="https://getcrg.com/candidate-support/">Candidate Support</a></li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <a href="https://getcrg.com/blog/">Blog</a>
          </li>
          <li>
            <a href="https://getcrg.com/locations/">Get Started</a>
          </li>
        </ul>
      </div>

      <div class="social-links">
        <ul>
          <li><a href="https://www.facebook.com/getCRG"><i class="fa fa-facebook2"></i></a></li>
          <li><a href="https://www.linkedin.com/company/crg-inc/"><i class="fa fa-linkedin2"></i></a></li>
        </ul>
      </div>

      <div class="menu-button" (click)="toggleMobileNav()">
        <span class="lines" [ngClass]="{ 'open': isMobileNavOpen }"></span>
      </div>
    </div>

    <div class="mobile-nav-links" [ngClass]="{ 'open': isMobileNavOpen }">
      <ul>
        <li>
          <a href="https://getcrg.com/">HOME<i class="fa fa-angle-right"></i></a>
        </li>

        <li (click)="toggleSubMenu('services')">
          <a>CRG SERVICES<i class="fa fa-angle-down fa-dropdown"></i></a>
        </li>

        <ul class="sub-menu" [ngClass]="{ 'open': currentOpenSubmenu === 'services' }">

          <li class="sub-menu-header" (click)="toggleSubMenu('services', 'search')">
            <div>
              <i class="fa fa-search"></i>
              SEARCH
            </div>
            <i class="fa fa-angle-down"></i>
          </li>
          <ul class="sub-sub-menu" [ngClass]="{ 'open': currentOpenSubSubmenu === 'search' }">
            <li><a href="https://getcrg.com/executive-recruitment/">SEARCH HOME<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/testimonials/">Testimonials<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/our-process/">Our Process<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/search-leadership/">Leadership<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/candidate-support/">Candidate Support<i class="fa fa-angle-right"></i></a></li>
          </ul>

          <li class="sub-menu-header" (click)="toggleSubMenu('services', 'staffing')">
            <div>
              <i class="fa fa-users2"></i>
              STAFFING
            </div>
            <i class="fa fa-angle-down"></i>
          </li>
          <ul class="sub-sub-menu" [ngClass]="{ 'open': currentOpenSubSubmenu === 'staffing' }">
            <li><a href="https://getcrg.com/staffing-agency/">STAFFING HOME<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/it-staffing/">Information Technology<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/accounting-recruitment/">Accounting & Finance<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/admin-hr-recruitment/">HR & Administration<i class="fa fa-angle-right"></i></a></li>
          </ul>

          <li class="sub-menu-header" (click)="toggleSubMenu('services', 'solutions')">
            <div>
              <i class="fa fa-desktop2"></i>
              SOLUTIONS
            </div>
            <i class="fa fa-angle-down"></i>
          </li>
          <ul class="sub-sub-menu" [ngClass]="{ 'open': currentOpenSubSubmenu === 'solutions' }">
            <li><a href="https://getcrg.com/it-solutions/">IT SOLUTIONS<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/it-project-management/">Project Management<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/managed-it-services/">Managed IT Services<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/it-consulting-services/">Consulting Services<i class="fa fa-angle-right"></i></a></li>
            <li><a href="https://getcrg.com/patch-management-service/">Patch Management as a Service<i class="fa fa-angle-right"></i></a></li>
          </ul>

        </ul>
        <li>
          <a href="https://getcrg.com/locations/">LOCATIONS<i class="fa fa-angle-right"></i></a>
        </li>

        <li (click)="toggleSubMenu('about')">
          <a>ABOUT CRG<i class="fa fa-angle-down fa-dropdown"></i></a>
        </li>
        <ul class="about-sub-menu" [ngClass]="{ 'open': currentOpenSubmenu === 'about' }">
          <li><a href="https://getcrg.com/leadership/">Leadership<i class="fa fa-angle-right"></i></a></li>
          <li><a href="https://getcrg.com/crg-values/">CRG Values<i class="fa fa-angle-right"></i></a></li>
          <li><a href="https://getcrg.com/community-focus/">Community Focus<i class="fa fa-angle-right"></i></a></li>
          <li><a href="https://getcrg.com/candidate-support/">Candidate Support<i class="fa fa-angle-right"></i></a></li>
        </ul>

        <li>
          <a href="https://getcrg.com/blog/">BLOG<i class="fa fa-angle-right"></i></a>
        </li>
        <li>
          <a href="https://getcrg.com/locations/">GET STARTED</a>
        </li>
      </ul>
    </div>
  </div>
</header>